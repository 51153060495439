<template>
  <DefaultTemplate>
    <div class="depositFunds-box">
      <div class="form-left">
        <div class="content">
          <div class="content">
            <div class="info-box">
              <p>{{ $t('deposit.nigeriaPaystack.header') }}</p>
              <p>{{ $t('deposit.method.desc', { method: $t('deposit.nigeriaPaystack.header') }) }}</p>
              <ul>
                <li>{{ $t('deposit.method.inst1', { number: '1' }) }}</li>
                <li>
                  {{ $t('deposit.method.inst13', { number: '2' }) }}
                </li>
                <li>{{ $t('deposit.method.inst14', { number: '3' }) }}</li>
              </ul>
              <p class="title">
                {{ $t('deposit.method.form.header', { method: $t('deposit.nigeriaPaystack.header') }) }}
              </p>
            </div>
            <div class="form_main">
              <el-form label-position="top" :model="paystackForm" ref="paystackForm" status-icon :rules="paystackRules">
                <div class="form-box">
                  <ul class="clearfix">
                    <li>
                      <AccountNumber
                        :supportedCurrenciesList="validCurrencies"
                        @setCurrency="setCurrency"
                        @setAccountNumber="setAccountNumber"
                      ></AccountNumber>
                    </li>
                    <li>
                      <el-form-item :label="$t('common.field.amt')" prop="amount">
                        <numeric-input
                          v-model="paystackForm.amount"
                          :currency="accountCurrency"
                          :precision="2"
                        ></numeric-input>
                      </el-form-item>
                    </li>
                    <li>
                      <el-form-item :label="$t('common.field.email')" prop="email">
                        <el-input v-model="paystackForm.email" data-testid="email"></el-input>
                      </el-form-item>
                    </li>
                  </ul>
                  <ul class="clearfix">
                    <li class="data">
                      <p>
                        {{
                          $t('deposit.default.rate.rate', { oldCurrency: accountCurrency, newCurrency: targetCurrency })
                        }}
                        <span>{{ rate }}</span>
                      </p>
                      <p>
                        NGN: <span>{{ rateChange }}</span>
                      </p>
                    </li>
                    <li>
                      <el-form-item :label="$t('common.field.imptNotes')">
                        <el-input v-model="paystackForm.notes" data-testid="imptNotes"></el-input>
                      </el-form-item>
                    </li>
                    <li>
                      <p>
                        Maximum amount for deposit: N250,000 per transaction Please note that for USSD/QR and Bank,
                        maximum transaction limit ranges from N20,000 - N100,000 depending on your Bank.
                      </p>
                    </li>
                  </ul>
                </div>
                <el-form-item>
                  <el-button class="btn-blue" :loading="loading" @click="submitForm()" data-testid="submit">
                    {{ $t('common.button.submit') }}
                  </el-button>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </div>

      <div class="form-right">
        <div class="logo circle bankNga"></div>
      </div>
    </div>
  </DefaultTemplate>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import AccountNumber from '@/components/form/AccountNumber';
import rounding from '@/util/rounding';
import DefaultTemplate from '@/components/template/deposit/DefaultTemplate';
import { validateEmail } from '@/util/validation';
import mixin from '@/mixins/page/deposit';
import { apiExchangeDepositRate, apiPaystack_payment } from '@/resource';

export default {
  name: 'Paystack',
  components: { NumericInput, AccountNumber, DefaultTemplate },
  mixins: [mixin],
  data() {
    const validateAmount = (rule, value, callback) => {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')));
      } else if (parseFloat(value) < this.USCMinLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLarger', {
              currencyType: this.currencyType,
              minLimit: Math.ceil(this.USCMinLimit)
            })
          )
        );
      } else if (this.rateChange > this.maxLimit) {
        callback(
          new Error(this.$t('common.formValidation.amtLess', { maxLimit: this.maxUSD, currency: this.accountCurrency }))
        );
      } else if (value % 1 !== 0 && this.accountCurrency === 'USC') {
        callback(new Error(this.$t('common.formValidation.integer')));
      } else {
        callback();
      }
    };
    return {
      paystackForm: {
        accountNumber: '',
        amount: '',
        email: '',
        notes: ''
      },
      paystackRules: {
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur'
          }
        ],
        email: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('common.field.email')
            }),
            trigger: 'blur'
          },
          {
            validator: validateEmail,
            trigger: 'blur'
          }
        ]
      },
      maxLimit: 250000,
      validCurrencies: ['USD', 'USC', 'GBP'],
      targetCurrency: 'NGN',
      rate: 0.0
    };
  },
  watch: {
    accountCurrency() {
      this.queryRate();
    }
  },
  computed: {
    rateChange() {
      return rounding(Math.ceil, this.rate * this.paystackForm.amount, 2);
    },
    maxUSD() {
      return rounding(Math.floor, this.maxLimit / this.rate, 2);
    }
  },
  methods: {
    setAccountNumber(accountNumber) {
      this.paystackForm.accountNumber = accountNumber;
    },
    submitForm() {
      this.$refs['paystackForm'].validate(valid => {
        if (valid) {
          this.loading = true;
          this.submitDeposit()
            .then(result => {
              if (result.data.msg && result.data.code == 0) {
                window.location = result.data.msg;
              } else {
                this.loading = false;
                this.errorMessage(this.$t('deposit.default.failed'));
              }
            })
            .catch(err => {
              this.loading = false;
              this.errorMessage(this.$t('deposit.default.failed'));
            });
        }
      });
    },
    queryRate() {
      apiExchangeDepositRate({
        fromCurrency: this.accountCurrency === 'USC' ? 'USD' : this.accountCurrency,
        toCurrency: this.targetCurrency
      })
        .then(resp => {
          if (resp.data.code == 0) {
            switch (this.accountCurrency) {
              case 'USC':
                this.rate = resp.data.data / 100;
                break;
              default:
                this.rate = resp.data.data;
                break;
            }

            if (this.paystackForm.amount) this.$refs['paystackForm'].validateField('amount');
          }
        })
        .catch(err => {
          this.errorMessage(
            this.$t('deposit.default.rate.result.rateError', {
              oldCurrency: this.accountCurrency,
              newCurrency: this.targetCurrency
            })
          );
        });
    },
    submitDeposit() {
      return apiPaystack_payment(
        {
          mt4Account: this.paystackForm.accountNumber,
          operateAmount: this.paystackForm.amount,
          depositAmount: this.rateChange,
          rate: this.USCrate,
          applicationNotes: this.paystackForm.notes,
          email: this.paystackForm.email
        },
        this.token
      );
    }
  },
  mounted() {
    this.queryRate();
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/deposit.scss';
</style>
